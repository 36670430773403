import request from '@/utils/request'
// 获取任务列表
export function task(data) {
  return request.get('/task/list', data)
}
// 删除任务
export function taskdelete(params) {
  return request.delete('/task/delete', { params })
}
// 任务类型
export function getTaskTypeList(data) {
  return request.get('/task/getTaskTypeList', data)
}
// 新增任务
export function tasksave(data) {
  return request.post('/task/save', data)
}
// 任务单体查询
export function taskget(data) {
  return request.get('/task/get', data)
}
// 编辑
export function taskupdate(data) {
  return request.put('/task/update', data)
}
// 查看明细
export function listUserTask(data) {
  return request.get('/task/listUserTask', data)
}
// 查看
export function getUserTask(data) {
  return request.get('/task/getUserTask', data)
}
