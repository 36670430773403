<template>
  <div class="container">
    <div class="head">
      <div class="header_left">
        <a-form-model class="head_form" :mode="taskForm">
          <a-form-model-item label="任务名称">
            <a-input placeholder="请输入任务名称" v-model="taskForm.name" :maxLength="20" />
          </a-form-model-item>
          <a-form-model-item label="状态" prop="state">
            <a-select v-model="taskForm.state" placeholder="全部">
              <a-select-option v-for="item in sourseList" :key="item.code" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="header_btn">
          <a-button class="search" @click="search">查询</a-button>
          <a-button class="reset" @click="reset">重置</a-button>
        </div>
      </div>
      <div class="header_right" @click="addtask">
        <img src="../../../../assets/images/add.png" alt="" />
        <span class="addtask">新增任务</span>
      </div>
    </div>
    <div class="content">
      <a-table style="width: 100%" :columns="columns" :data-source="data" :pagination="false" :row-selection="rowSelection">
        <!-- 序号 -->
        <span slot="(index,text,record)" slot-scope="index, record, text">{{ text }}</span>
        <!-- 任务类型 -->
        <span slot="typeName" slot-scope="text, records">{{ records.echoMap ? records.echoMap.typeName : '--' }}</span>
        <!-- 积分值 -->
        <span slot="integration" slot-scope="text, records">{{ records.integration ? records.integration.toFixed(1) : '--' }}</span>
        <!-- 状态 -->
        <span slot="state" slot-scope="text, records">
          {{ records.echoMap ? records.echoMap.state : '--' }}
        </span>
        <span class="action" slot="action" slot-scope="text, records">
          <span class="edit" @click="toWatch(records.id)">查看明细</span>
          <!-- <router-link class="edit" :to="{ path: `/member/task/watch/list?id=${records.id}` }">查看明细</router-link> -->
          <router-link
            class="edit"
            :to="{
              path: `/member/task/addtask?id=${records.id}`
            }"
            >编辑</router-link
          >
          <a-popconfirm title="是否删除该信息？" ok-text="确认" cancel-text="取消" @confirm="yes(records.id)" @cancel="no">
            <span class="del">删除</span>
          </a-popconfirm>
        </span>
      </a-table>
      <Pagination :current="current" :page-size="size" :total="total" @change="change" />
    </div>
  </div>
</template>
<script>
import local from '@/utils/local'
import { task, taskdelete } from '@/api/task'
import Pagination from '@/components/pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      taskForm: {
        name: '',
        state: undefined
      },
      selectedRowKeys: [],
      data: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '任务名称',
          dataIndex: 'name'
        },
        {
          title: '任务类型',
          dataIndex: 'typeName',
          scopedSlots: { customRender: 'typeName' }
        },
        {
          title: '任务积分值',
          dataIndex: 'integration',
          scopedSlots: { customRender: 'integration' }
        },
        {
          title: '开始时间',
          dataIndex: 'startTime'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime'
        },
        {
          title: '限制名额',
          dataIndex: 'seats'
        },
        {
          title: '剩余名额',
          dataIndex: 'remainSeats'
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      size: 10,
      total: 0,
      current: 1,
      sourseList: [
        {
          name: '未开始',
          code: 0
        },
        {
          name: '进行中',
          code: 1
        },
        {
          name: '已结束',
          code: 2
        },
        {
          name: '已抢完',
          code: 3
        }
      ]
    }
  },
  created() {
    this.getTaskList()
  },
  methods: {
    async getTaskList() {
      const data = await task({
        name: this.taskForm.name,
        state: this.taskForm.state,
        current: this.current,
        size: this.size
      })
      if (data.code === 200) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 分页
    change(page, size) {
      this.current = page
      this.size = size
      this.getTaskList()
    },
    // 查询
    search() {
      this.getTaskList()
    },
    // 重置
    reset() {
      this.taskForm = {}
      this.getTaskList()
    },
    // 新增任务
    addtask() {
      this.$router.push('/member/task/addtask')
    },
    // 删除
    async yes(id) {
      const data = await taskdelete({ id: id })
      console.log(data)
      this.$message.success('删除成功!')
      this.getTaskList()
    },
    no() {},
    // 查看明细
    toWatch(id) {
      const Id = id
      local.set('Id', id)
      this.$router.push(`/member/task/watch/list?id=${Id}`)
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        Selections: [
          {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            }
          },
          {
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows)
            }
          },
          {
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows)
            }
          }
        ],
        onSelection: this.onSelection
      }
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  background: #f8f9fe;
}

.head {
  background: #ffffff;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.header_left {
  display: flex;
  align-items: center;
}

.search {
  width: 68px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 0 14px;
}

.reset {
  width: 68px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.head_form {
  display: flex;
  align-items: center;
}

.head_form >>> .ant-form-item {
  display: flex;
  margin: 0;
  height: 36px;
  align-items: center;
}

.head_form >>> .ant-form-item-label {
  height: 36px;
}

.head_form >>> .ant-form-item-control {
  height: 36px;
}

.header_right {
  display: flex;
  align-items: center;
  color: #f44600;
  line-height: 20px;
  cursor: pointer;
}

.addtask {
  color: #f44600;
  margin-left: 3px;
}

.content {
  margin-top: 20px;
  background: #fff;
  height: calc(100vh - 196px);
  display: flex;
  flex-direction: column;
}

.content >>> .ant-table-wrapper {
  overflow-y: auto;
  flex: 1;
}

.edit,
.del {
  font-size: 14px;
  color: #f44600;
  margin-right: 20px;
  cursor: pointer;
}

.content >>> .ant-spin-nested-loading {
  width: 100%;
}

* >>> .ant-table-thead > tr > th {
  background: #f7f8f9 !important;
}

* >>> .ant-table-tbody > tr > td {
  background: #fff !important;
}

.head >>> .ant-select-selection--single {
  width: 200px;
  height: 36px;
}

.head_form >>> .ant-input {
  width: 200px;
  height: 36px;
  margin-right: 14px;
}

.header_btn {
  display: flex;
  align-items: center;
}

.head_form >>> .ant-select-selection__rendered {
  height: 36px;
  display: flex;
  align-items: center;
}
.head_form >>> .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  margin-top: -11px;
}
</style>
